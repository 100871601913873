.visaInfo-container{
    box-shadow: 0 0 1rem rgba(0,0,0,.1);
    border-radius: .5rem;
    position: relative;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    min-height: 100px;
    justify-content: center;
    align-items: center;
}

.visaInfo-results{
    font-size: 50px;
    font-family: var(--vt-font-book);
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    min-height: 100px;
    justify-content: center;
    align-items: center;
}

.successIcon{
    color:green
}