/*
*
* Header Pro
*
*
*/
.App-header-customisation {
  position: relative;
  background-color: white;
  height: 48px;
  display: flex;
  flex-direction: column;
  /*font-size: calc(10px + 2vmin);*/
  color: var(--vt-text-color-dark);
  border-bottom: 1px solid rgba(239, 223, 223, 0.46);
  animation: fadeinTop .1s ease;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100vw;
  overflow-x: auto;
  z-index: 102;
}



/* width */
.App-header-customisation::-webkit-scrollbar{
width: 1px;
height: 3px;
}

/* Track */
.App-header-customisation::-webkit-scrollbar-track {
background: #f1f1f1;
}

/* Handle */
.App-header-customisation::-webkit-scrollbar-thumb {
background: #888;
}

/* Handle on hover */
.App-header-customisation::-webkit-scrollbar-thumb:hover {
background: #555;
}

.ant-modal-content{
  background-color: transparent !important;
  box-shadow: none;
}

.ant-modal-close{
  display: none;
}

.customisation-menu-wrapper{
  flex:1;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 25px;
  margin-right: 25px;
}

.customisation-menu-text{
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 14px;  
  display: flex;
  align-items: center;
  color: var(--vt-text-color-dark);
}

.customisation-menu-item{
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 32px;
  transition: all .5s ease-in-out;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  

}
.customisation-menu-item:hover{
  background-color: var(--vt-blue);
  border-bottom: 1px solid white;
  cursor: pointer;
}

.App-header-customisation .ant-tag-checkable, .App-header-customisation .ant-input,  .App-header-customisation span{
  border: 0px solid #c0d3e2;
  font-size: 15px;
  margin: 5px;
  text-transform: capitalize;
  font-weight: 100;
  box-shadow: none;
  background-color: transparent;
  color:var(--vt-text-color-dark);
}
.App-header-customisation  .ant-tag-checkable-checked{
border: 1px solid #efdfdf;
  box-shadow: none;
  padding: 5px;
  border-radius: 10px;
  
}

.App-header-customisation .ant-tag-checkable:hover, .App-header-customisation .ant-input:hover{
  cursor: pointer;
  box-shadow: none
}