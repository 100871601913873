.big-title-icon-animation{
    animation: bt-icon-animation 1s infinite; 
}

@keyframes bt-icon-animation {
    0%   { 
        opacity: 1; 
        transform: translateX(0);
     }
  
    100% { 
        opacity: 0;  
        transform: translateX(20px);
    }
  }