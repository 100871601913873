.Personalize-bg{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    min-width:100%;
    min-height: calc(100vh - 100px);
    position: relative;
    animation: fadIn  2s ease-in;
}

.Personalize-section{
    padding-top: 100px;
    padding-bottom: 100px;
    min-height: calc(100vh - 100px);
}

.Personalize-button{
    background-color: var(--vt-black);
    padding: 12px;
    border-radius: 15px 15px 0 0;
    box-shadow:var(--vt-box-shadow);
    transition: all 0.4s ease-in-out;
    cursor: pointer;
    
}

.Personalize-button:hover{
    transform: scale(1.05)
}

@keyframes Personalize-reveal {
    0%   { box-shadow:inset 0 0 0 2000px rgba(71, 177, 255, 1); }
    100% { box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.47);}
  }

  @keyframes Personalize-animate-bottom {
   
    50%   { 
        border-bottom-right-radius:30% 50%;
        border-bottom-left-radius:70% 50%;
      
    }
    100% { 
        border-bottom-right-radius:20% 50%;
        border-bottom-left-radius:80% 50%;
      
       
    }
  }

  @keyframes bigBg-reveal {
    0%   { box-shadow:inset 0 0 0 2000px white }
    100% { box-shadow:inset 0 0 0 2000px rgba(71, 177, 255, 0) }
  }

  /*Extra small devices*/
@media only screen and (max-width: 600px) {
    .Personalize-bg{
     
        min-height: calc(150vh - 100px);
       
    
    }
}