.contact-us{
    display: flex;
    justify-content: center;
    position: relative;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.contact-us .logo{
    max-width: 80vw;
}

.contact-details{
margin-top: 30px;
text-align: center;
font-size: 20px;

}