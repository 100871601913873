#PlaceDetailContainer{
 min-height: 50vh;
 color: white;
}

#PlaceDetailContainer h1{
    font-family: var(--vt-font-black);
    font-size: 40px;
    margin: 0;
    color:white
}

#PlaceDetailContainer .address{
    font-size: 17px;
}

#PlaceDetailContainer p{
    padding-top: 40px;
    font-size: 22px;
    font-weight: 200;
    text-align: justify;

}

#PlaceDetailContainer .meta{
    font-size: 17px;
    border: 1px solid #dce9f2;
    font-family: var(--vt-font-bold);
    width:100%;
    margin: 10px;
   
  
}

#PlaceDetailContainer .meta:after{
    content: "";
    clear: both;
    display: table;
}

.venu-container{
    background: #e1e5e9;
    margin-bottom: 20px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
}

.venu-container h1{
    font-size: 20px !important;
    text-transform: capitalize;
    color: var(--vt-black) !important;
}

.venu-container h2{
  margin: 0;
  color: var(--vt-black);
}

.venu-container span{
    font-size: 20px;
    text-transform: capitalize;
    margin: 0;
    color: var(--vt-black);
}