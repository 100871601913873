
@import url('https://fonts.googleapis.com/css?family=Fira+Sans+Condensed:100,200,300,300i,400,400i,500,700,900&display=swap');

body {
  margin: 0;
  min-height:100%;
  display: block;
  text-rendering:optimizeLegibility;
  transition: transform .3s cubic-bezier(.215,.61,.355,1),opacity .3s cubic-bezier(.215,.61,.355,1),-webkit-transform .3s cubic-bezier(.215,.61,.355,1);
  font-family: 'Fira Sans Condensed', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}