@font-face {
    font-family: 'CircularStd';
    src: url(./CircularStd-Black.ttf)
}

@font-face {
    font-family: 'CircularStdBold';
    src: url(./CircularStd-Bold.ttf)
}


@font-face {
    font-family: 'CircularStdBook';
    src: url(./CircularStd-Book.ttf)
}

@font-face {
    font-family: 'CircularStdMedium';
    src: url(./CircularStd-Medium.ttf)
}