.site-footer-newsletter-wrapper{
    background: white;
    box-shadow: var(--vt-shadow-small);
    color: var(--vt-text-color-grey);
    margin-bottom: 50px;
    text-align: center;
}



.site-footer-newsletter-wrapper .ant-input{
    background-color: transparent;
    border:none;
    border-bottom: 1px solid black;
    border-radius: 0;
    text-align: center;
    color: var(--vt-text-color-grey);
}

.site-footer-newsletter-wrapper h1{
    margin:0;
    font-family: var(--vt-font-black);
    font-size: 5vw;
    color: var(--vt-text-color-grey);
}
.site-footer{
    width: 100%;
    min-height: 30vh;
    background-color: white;
    
    position: relative;
    z-index: 99;
}
.site-footer .logoWrapper{
    display: flex;
    justify-content: center;
    

}

.site-footer .logo{
    height: 100px;
    margin:20px;
    flex: 1 1 0px;
    object-fit: contain;
    max-width: 207px;

}

.Footer-heading{
    margin-bottom: 17px;
    font-size: 17px;
   
}

.Footer-list{
    list-style: none;
    margin: 0;
    padding: 0;
}

.Footer-list li{
    font-size: 16px;
    font-weight: 200;;
    line-height: 23px;

}

.Footer-sub-wrapper{
    flex:1;
    display: flex;
    align-items: bottom;
    justify-content: center;
    margin-left: 25px;
    margin-right: 25px;
    z-index: 100;
   
 
}



.Footer-wrapper{
    flex:1;
    display: flex;
    align-items: bottom;
    justify-content: space-between;
    margin-left: 25px;
    margin-right: 25px;
    z-index: 100;
}

/*Extra small devices*/
@media only screen and (max-width: 600px) {
    .Footer-wrapper{
      display: none;
    }

    .site-footer .logo{
        height: 50px;
        margin:20px
    }
   
  }