.App-header-classic-mice {
  background-color: white;
  height: 77px;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  position: relative;
  z-index: 5;
  border-bottom:3px solid var(--vt-blue);
}

.App-header-classic-mice .ant-badge-count{
  top:15px;
  right: 10px;
}

.App-header-classic-mice-mobile{
  position:fixed ;
  bottom:0;
  z-index: 99999;
}

.App-header-classic-mice .mega-menu-modal{
  position: fixed;
  z-index: 1026;
  bottom: 0;
  height: calc(100vh);
  width: 100vw;
  background-color: var(--vt-black);
  overflow-y: auto;
}

.App-header-classic-mice .mega-menu-modal-left-content h1{
  color:white;
  font-family: var(--vt-font-black);
  font-size: 40px;
  transition: all 1s;
  cursor: pointer;
}
.App-header-classic-mice .mega-menu-modal-sb-title{
  color:rgba(255,255,255,.2);
  font-family: var(--vt-font-black);
  font-size: 30px;
  margin-bottom: 30px;
  cursor: pointer;
}
.App-header-classic-mice .mega-menu-modal-left-content h1:hover{
  color:var(--vt-blue)
}


.App-header-classic-mice .mm-close-btn{
  position: fixed;
  top: 20px;
  right: 80px;
  cursor: pointer;

}
.App-header-classic-mice-fixed {
  position:fixed !important;
  top:0;
  animation: fadeinTop .5s;
}



.App-header-classic-mice .Main-menu-wrapper{
  flex:1;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 25px;
  margin-right: 25px;
 /* box-shadow: var(--vt-box-shadow);*/

}

.App-header-classic-mice .Main-menu-text{

  font-style: normal;
  font-weight: 100;
  font-size: 22px;
  line-height: 28px;  
  display: flex;
  align-items: center;
  color: var(--vt-text-color-dark);
  text-transform: capitalize;
}

.App-header-classic-mice .special .Main-menu-text{
  color:white;
  text-transform: capitalize;
}


.App-header-classic-mice .Main-menu-item{
  display: flex;
  justify-content: center;
  text-align: left;
  width: 100%;
  height: 77px;
 
  transition: all .5s ease-in-out;
}



.App-header-classic-mice .Main-menu-item:hover{
  background-color: rgb(243, 243, 243);
  transform: scale(1.01);
  cursor: pointer;
  border-bottom: 10px solid var(--vt-blue);
}


.App-header-classic-mice .Main-menu-logo-text{
  margin:0;
  color: var(--vt-text-color-dark);
  font-size: 10px ;
  padding-top: 5px;
  font-family: var(--vt-font-bold);
  
}

.App-header-classic-mice .menu-icon-image{
  width: auto;
  height: 50px;
  padding-right: 20px;
  padding-top: 10px;
  margin-top: 7px
}

.App-header-classic-mice .menu-icons-right{
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
}

.App-header-classic-mice .center-image{
  display:table-cell; 
  vertical-align:middle; 
  text-align:center;
  min-width:190px
}

.App-header-classic-mice .header-icons{
  height: 37px;
  width: auto;
}

@keyframes fadeinTop {
  from {
      transform:translateY(-300px);
  }
  to {
    transform:translateY(0);
  }
}

.App-header-classic-mice  .menu-icons-right .ant-select-selection{
  background-color: transparent;
  color: white;
  border: none;
  margin: 0;
  padding: 0;
 box-shadow: none;
 width: 60px;
 height: 100%;
}


/*Extra small devices*/
@media only screen and (max-width: 600px) {
  .Main-menu-wrapper, .menu-icons-right{
    display: none !important;
  }
  .App-wrapper{
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
 
}