.App-header-classic {
  background-color:white;
  height: 77px;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  position: relative;
  z-index: 5;
  border-bottom: 1px solid rgba(255,255,255,.1);
}

.mega-menu-modal{
  position: fixed;
  z-index: 1026;
  bottom: 0;
  height: calc(100vh);
  width: 100vw;
  background-color: rgba(0,0,0,.6);
  backdrop-filter: blur(10px);
  overflow-y: auto;
}

.mega-menu-modal-left-content h1{
  color:white;
  font-family: var(--vt-font-black);
  font-size: 40px;
  transition: all 1s;
  cursor: pointer;
}
.mega-menu-modal-sb-title{
  color:rgba(255,255,255,.2);
  font-family: var(--vt-font-black);
  font-size: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  text-transform: capitalize;
}
.mega-menu-modal-left-content h1:hover{
  color:var(--vt-blue)
}


.mm-close-btn{
  position: fixed;
  top: 60px;
  right: 80px;
  z-index: 99999999;
  cursor: pointer;
  background-color: black;
  padding: 5px;

}
.App-header-classic-fixed {
  position:fixed ;
  top:0;
  animation: fadeinTop .5s;
}

.App-header-classic-mobile {
  position:fixed ;
  bottom:0;
  z-index: 99999;
  animation: fadeinTop .5s;
}



.Main-menu-wrapper{
  flex:1;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: 25px;
  margin-right: 25px;
 /* box-shadow: var(--vt-box-shadow);*/

}

.App-header-classic .Main-menu-text{
  font-family: var(--vt-font-book);
  font-style: normal;
  font-weight: 100;
  font-size: 25px;
  line-height: 26px;  
  display: flex;
  align-items: center;
  color: var(--vt-text-color-dark);
  text-transform: capitalize;
}

.App-header-classic .Main-menu-item{
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 77px;
 
  transition: all .5s ease-in-out;
 
}

.App-header-classic .Main-menu-item:nth-child(1){
 
}

.App-header-classic .Main-menu-item:hover{
  background-color: var(--vt-blue-sec);
  transform: scale(1.05);
  cursor: pointer;
}

.App-header-classic .Main-menu-logo-text{
  margin:0;
  color: white ;
  font-size: 10px ;
  padding-top: 5px;
  font-family: var(--vt-font-bold);
  
}

.menu-icon-image{
  width: auto;
  height: 50px;
  padding-right: 20px;
  padding-top: 10px;
  margin-top: 7px
}

.menu-icons-right{
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
}




.center-image{
  display:table-cell; 
  vertical-align:middle; 
  text-align:center;
  min-width:190px
}

.header-icons{
  height: 37px;
  width: auto;
}

@keyframes fadeinTop {
  from {
      transform:translateY(-300px);
  }
  to {
    transform:translateY(0);
  }
}

.menu-icons-right .ant-select-selection{
  background-color: transparent;
  color: white;
  border: none;
  margin: 0;
  padding: 0;
 box-shadow: none;
 width: 60px;
 height: 100%;
}

/*Extra small devices*/
@media only screen and (max-width: 600px) {
  .Main-menu-wrapper, .menu-icons-right{
    display: none;
  }
  .App-wrapper{
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .App-header-classic-fixed {
    position:fixed !important;
    bottom:0 !important;
    animation: fadeinTop .5s;
  }
  
}