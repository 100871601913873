.quote-input-container{
    position: fixed;
    bottom: 10px;
    
    width: 99%;
    right: 0;
    left: 0;
    margin: 0 auto;
  
    z-index: 9999;
}
.send-quote-button{
  position: absolute;
  right: 0;
  top:0;
  z-index: 99999;
  height: 100%;

}

.send-quote-button .ant-btn-primary{
  height: 100%;
  box-shadow: var(--vt-shadow-tiny);
  border-radius: 0;
}

.quote-messages-container{
    padding: 0 20vw;

}

.quote-messages-container ul{
    list-style: none;
    
}

.quote-messages-container ul li{
    padding: 10px;
    margin: 10px;
    background-color: #F4F4F8;
    border-radius: 7px;
    display: inline-block;
    text-align: justify;
    
}

.quote-messages-container .owner{
  background-color: var(--vt-blue);
  color:white
}

.quote-messages-container .owner-container{
   
    text-align: right;
  }

.quote-messages-container .other{
    text-align: right;
  }

  .quote-messages-container h1{
    font-family: var(--vt-font-black);
  }