
.adherent-container img{
    cursor: pointer;
}

.news-holder{
    display: flex;
    flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.news-card{
 box-shadow: var(--vt-shadow-small);
 text-align: center;
 margin: 10px;
 
}

.news-card h2{
    font-size: 30px;
    font-weight: 900;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: rgb(45, 55, 62);
    color: white !important;

}


.adherent-container .adherent-section-title{
    height: 40vh;
    background-color: var(--vt-blue);
    text-align: center;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    
}

.adherent-container  h1{
    font-size: 20px;
   
    font-family: var(--vt-font-black);
    cursor: pointer;
    color: var(--vt-text-color-dark);
}

.adherent-container  h2{
    font-size: 15px;
   
    font-family: var(--vt-font-medium);
    cursor: pointer;
    color: var(--vt-text-color-dark);
}


.adherent-text-short{
    text-align: justify;
    width: 90%;
    cursor: pointer;
    color: var(--vt-text-color-dark);
}

.adherent-right-list{
    max-height: 250px
}

.adherent-photo-bank-banner {
    border:2px solid #e2e2e2;
    border-radius: 20px;
}

.adherent-news-link{
    color:'black'
}