.Top-experiences_slider_image{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: var(--vt-shadow-small);
    transition: .5s all;
}

.Top-experiences_slider_image:hover{
  
    box-shadow: none;
}

