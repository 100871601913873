#Nearby-component h3{
    font-size: 20px;
    font-family: var(--vt-font-medium);
    cursor: pointer;
    transition: all .2s;
    opacity: 0.3;
}

#Nearby-component h3:hover{
    transform: scale(1.1);
    color:var(--vt-blue);
    opacity:1
}

.Nearby-filters-clear h3{
  
    color: white;
}

#Nearby-component .Nearby-filters-selected h3{
    transform: scale(1.1);
    color:var(--vt-blue);
    opacity: 1;
}

#Nearby-component .Nearby-filters-selected h3:after{
    display: block;
    width: 100%;
    position: absolute;
    margin: 0 auto;
    height: 20px;
    color: var(--vt-blue);
    content:'.';
    font-size: 50px;
    top:0;
    text-align: center;
}

.Nearby-wrapper{
    flex:1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: 20%;
    margin-right: 20%;
}

/*Extra small devices*/
@media only screen and (max-width: 600px) {
   
    .Nearby-wrapper{
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    #Nearby-component h3{
        font-size: 14px;
     
    }
  }