/*
*
* Header Pro
*
*
*/
.App-header-pro {
    position: relative;
    z-index: 10;
    background-color: #31a7fe;
    height: 31px;
    display: flex;
    flex-direction: column;
    /*font-size: calc(10px + 2vmin);*/
    color: white;
    border-bottom: 1px solid #3496df;
    box-shadow: var(--vt-box-shadow);
    font-family: var(--vt-font-medium);
    
  }

  .App-header-mobile {
    position: relative;
    z-index: 10;
    background-color: #2d3943;
    height: 41px;
    display: flex;
    width:100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /*font-size: calc(10px + 2vmin);*/
    color: black;
   border-bottom: #3496df 1px solid;
    font-family: var(--vt-font-thin);
    text-transform: uppercase;
 
  }

  .App-header-mobile a {
    color:white
  }



  .App-header-mobile >div{
   
    margin-left: 10px;

  }

  .App-header-mobile .ant-select-selection{
 
  }
  
  .Pro-menu-text{
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 14px;  
    display: flex;
    align-items: center;
    color: #FFFFFF;
    text-transform: uppercase;
  }

  .Pro-menu-text:hover{
    color:white
  }
  
  .Secondary-menu-item{
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 32px;
    transition: all .5s ease-in-out;
    border-bottom: 1px solid transparent;
    margin-left: 10px;
    margin-right: 10px;
    
  
  }
  .Secondary-menu-item:hover,  .active{
    background-color: var(--vt-blue);
    border-bottom: 5px solid white;
    cursor: pointer;
    transform: scale(1.1);
  }
  

