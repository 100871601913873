.Home-customize-form{
    background-color: white;
    width: 479px;
    height: 100%;
    border-radius: 7px;
    padding: 32px 32px 24px 32px;
    box-shadow: var(--vt-shadow-small);
}



.Home-customize-form-small{
    width: 100% !important;
}

.Home-customize-form h1{
    font-family: var(--vt-font-black);
    font-size: 33px;
    
    color: var( --vt-text-color-dark);
   
}

.Home-customize-form p{
    
    font-size: 15px;
    letter-spacing: -0.01em;
    color: #484848;
    margin:0
 
}


.Home-customize-form .ant-tag-checkable, .Home-customize-form .ant-input, .ant-radio-button-wrapper{
    border: 1px solid #c0d3e2;
    margin: 5px;
    text-transform: capitalize;
    font-family: var(--vt-font-black);
    box-shadow: var(--vt-shadow-tiny);
}
.Home-customize-form  .ant-tag-checkable-checked, .ant-radio-button-wrapper-checked{
    box-shadow: none;
    border: 1px solid var(--vt-blue);
}

.Home-customize-form .ant-tag-checkable:hover, .Home-customize-form .ant-input:hover, .ant-radio-button-wrapper:hover{
    cursor: pointer;
    box-shadow: none
}

.ant-radio-button-wrapper-checked{
    transition: all 1s
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    background-color:#1890ff;
    color:white
}

/*Extra small devices*/
@media only screen and (max-width: 600px) {
    .Home-customize-form{
        width: 100%;
    }
}